var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"prebilled-amount"},[(
      _vm.$te(
        'prebilled.' + _vm.mode + '.tooltipBaseOnPaymentTypeId.' + _vm.paymentTypeId
      )
    )?_c('v-tooltip',{attrs:{"top":"","content-class":"prebilled-amount-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-alert',_vm._g(_vm._b({attrs:{"dense":"","outlined":"","type":"info"}},'v-alert',attrs,false),on),[_c('div',[_vm._v(" "+_vm._s(_vm.prebilledAmountText)+" "),_c('strong',[_vm._v(" "+_vm._s(_vm.$n(_vm.prebilledAmount, "currency"))+" ")])])])]}}],null,false,298127875)},[_c('span',{domProps:{"innerHTML":_vm._s(
        _vm.$t(
          'prebilled.' + _vm.mode + '.tooltipBaseOnPaymentTypeId.' + _vm.paymentTypeId
        )
      )}})]):_c('v-alert',{attrs:{"dense":"","outlined":"","type":"info"}},[_c('div',[_vm._v(" "+_vm._s(_vm.prebilledAmountText)+" "),_c('strong',[_vm._v(" "+_vm._s(_vm.$n(_vm.prebilledAmount, "currency"))+" ")])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }